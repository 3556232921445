<script>
  import { createEventDispatcher, onMount } from "svelte";
  import {
    useForm,
    required,
    validators,
    email,
    maxLength,
    Hint,
    HintGroup,
  } from "svelte-use-form";
  import {
    format,
    subYears,
    addDays,
    isBefore,
    isAfter,
    isEqual,
  } from "date-fns";
  import { limitField, checkInvalidForm } from "../../commons";
  import { getCountries, getRoyaltyProgram } from "../service";
  import { _ } from "svelte-i18n";
  import SelectSearchable from "../../components/select-searchable.svelte";
  import ReloadingComponent from "../../components/reloading-component.svelte";
  export let propertyInformation;
  export let guestAppSettings;
  export let reservation;
  export let isADesktop;
  export let readOnly;
  const dispatch = createEventDispatcher();
  let showDocumentType = true;
  let loading = true;
  let royaltyProgram = null;
  let listCountries = [];
  let listDocumentsTypes = [
    {
      codename: "passport",
      name: $_("Passport"),
    },
    {
      codename: "dni",
      name: $_("Identity card"),
    },
    {
      codename:
        reservation.type == "child" || reservation.type == "baby"
          ? "birth-certificate"
          : "licence-driver",
      name:
        reservation.type == "child" || reservation.type == "baby"
          ? $_("Birth Certificate")
          : $_("Drive Licence"),
    },
  ];
  let documentDefaultValue = listDocumentsTypes.map((lst) => ({
    ...lst,
    id: lst.codename,
  }))[0];

  const dataForm = useForm();

  const nextStage = () => dispatch("nextStage", "nextStage");

  const dateChild = (value, form) => {
    if (reservation.type == "child") {
      if (
        value &&
        isBefore(new Date(value), subYears(new Date(), 2)) &&
        isAfter(new Date(value), subYears(subYears(new Date(), 2), 18))
      ) {
        return null;
      } else return { dateChild: true };
    }
  };

  const dateAdult = (value, form) => {
    if (reservation.type != "child" && reservation.type != "baby") {
      if (
        value &&
        isBefore(new Date(value), subYears(new Date(), 18)) &&
        isAfter(new Date(value), subYears(subYears(new Date(), 18), 100))
      ) {
        return null;
      } else return { dateAdult: true };
    }
  };

  const dateBaby = (value, form) => {
    if (reservation.type == "baby") {
      if (
        value &&
        isBefore(new Date(value), new Date()) &&
        isAfter(new Date(value), subYears(new Date(), 2))
      ) {
        return null;
      } else return { dateBaby: true };
    }
  };

  const dateIsFuture = (value, form) => {
    if (
      value &&
      (isEqual(
        new Date(format(addDays(new Date(value), 1), "yyyy-MM-dd")),
        new Date(format(new Date(), "yyyy-MM-dd"))
      ) ||
        isAfter(new Date(value), new Date()))
    ) {
      return null;
    } else return { dateIsFuture: true };
  };

  onMount(() => {
    loading = true;
    Promise.all([
      getCountries({ propertyId: propertyInformation.id }).then((response) =>
        response.ok ? response.json() : Promise.reject(response)
      ),
      getRoyaltyProgram({ propertyId: propertyInformation.id }).then(
        (response) => (response.ok ? response.json() : Promise.reject(response))
      ),
    ]).then(([countriesData, royaltyData]) => {
      listCountries = countriesData;
      royaltyProgram = royaltyData;
      loading = false;
      setTimeout(
        () =>
          document.querySelector("#continue").addEventListener("click", () => {
            if (!reservation.nationality.id)
              reservation.nationality = undefined;
            $dataForm.touched = true;
          }),
        200
      );
    });
  });
</script>

{#if loading}
  <ReloadingComponent bind:managementColors={guestAppSettings} />
{:else}
  <section class="container">
    <div
      style={readOnly ? "pointer-events: none;" : ""}
      class="my-container-check-in"
    >
      <div class="col-12">
        <h1 style="margin-bottom: 16px;" class="card-title">
          {$_("Guest data")}
        </h1>
        <div class="card">
          <div class="card-body">
            <form class={isADesktop ? "row" : ""} id="dataForm" use:dataForm>
              <div class="row">
                <div class="mb-4 col-12 col-sm-12 col-lg-6">
                  <label
                    for="firstName"
                    class="form-label label__data {!$dataForm.firstName
                      ?.valid && $dataForm.firstName?._touched
                      ? 'field-invalid'
                      : ''}"
                  >
                    {$_("First name")}
                  </label>
                  <input
                    use:validators={[required, maxLength(40)]}
                    type="text"
                    class="form-control"
                    id="firstName"
                    name="firstName"
                    autocomplete="given-name"
                    maxlength="40"
                    placeholder={$_("Enter your first name")}
                    bind:value={reservation.firstName}
                    on:input={(ev) => limitField(ev, /[^a-zÀ-ÿñ\s]/gi)}
                  />
                  <Hint
                    class="help-block help-block-left field-invalid"
                    for="firstName"
                    on="required"
                  >
                    {$_("This field is required")}
                  </Hint>
                </div>

                <div class="mb-4 col-12 col-sm-12 col-lg-6">
                  <label
                    for="lastName"
                    class="form-label label__data {!$dataForm.lastName?.valid &&
                    $dataForm.lastName?._touched
                      ? 'field-invalid'
                      : ''}"
                  >
                    {$_("Last name")}
                  </label>
                  <input
                    use:validators={[required, maxLength(100)]}
                    type="text"
                    class="form-control"
                    id="lastName"
                    name="lastName"
                    maxlength="40"
                    autocomplete="family-name"
                    placeholder={$_("Enter your last name")}
                    on:input={(ev) => limitField(ev, /[^a-zÀ-ÿñ\s]/gi)}
                    bind:value={reservation.lastName}
                  />
                  <Hint
                    class="help-block help-block-left field-invalid"
                    for="lastName"
                    on="required"
                  >
                    {$_("This field is required")}
                  </Hint>
                </div>
              </div>

              <div class="row">
                <div class="mb-4 col-12 col-sm-12 col-lg-6">
                  <label
                    for="birthday"
                    class="form-label label__data {!$dataForm.birthday?.valid &&
                    $dataForm.birthday?._touched
                      ? 'field-invalid'
                      : ''}"
                  >
                    {$_("Date of birth")}
                  </label>
                  <input
                    use:validators={[required, dateAdult, dateBaby, dateChild]}
                    type="date"
                    class="form-control"
                    id="birthday"
                    name="birthday"
                    autocomplete="bday"
                    placeholder={$_("Ex") + ": 17/12/94"}
                    min={() =>
                      reservation.type == "baby"
                        ? format(
                            new Date(subYears(new Date(), 2)),
                            "yyyy-MM-dd"
                          )
                        : reservation.type == "child"
                          ? format(
                              new Date(subYears(new Date(), 18)),
                              "yyyy-MM-dd"
                            )
                          : format(
                              new Date(subYears(subYears(new Date(), 18), 100)),
                              "yyyy-MM-dd"
                            )}
                    max={(() =>
                      reservation.type == "child" || reservation.type == "baby"
                        ? format(new Date(), "yyyy-MM-dd")
                        : format(
                            new Date(subYears(new Date(), 18)),
                            "yyyy-MM-dd"
                          ))()}
                    bind:value={reservation.birthday}
                  />
                  <!-- svelte-ignore missing-declaration -->
                  <HintGroup for="birthday">
                    <Hint
                      class="help-block help-block-left field-invalid"
                      on="required">{$_("This field is required")}</Hint
                    >

                    <Hint
                      class="help-block help-block-left field-invalid"
                      on="dateAdult"
                      hideWhenRequired
                    >
                      {$_("Must be over or equal to 18 years old")}
                    </Hint>

                    <Hint
                      class="help-block help-block-left field-invalid"
                      on="dateChild"
                      hideWhenRequired
                    >
                      {$_("The child must be between 3-17 years old")}
                    </Hint>

                    <Hint
                      class="help-block help-block-left field-invalid"
                      on="dateBaby"
                      hideWhenRequired
                    >
                      {$_("The baby must be under or equal 2 years old")}
                    </Hint>
                  </HintGroup>
                </div>
                <div class="mb-4 col-12 col-sm-12 col-lg-6">
                  <label
                    for="nationality"
                    class="form-label label__data {!reservation.nationality &&
                    $dataForm.nationality?.touched
                      ? 'field-invalid'
                      : ''}"
                  >
                    {$_("Nationality")}
                  </label>
                  <SelectSearchable
                    bind:list={listCountries}
                    name="nationality"
                    id="nationality"
                    class="form-control placehold__color"
                    placeholder={$_("Select nationality")}
                    required={true}
                    autocomplete="off"
                    pattern={/[^a-zÀ-ÿñ\s]/gi}
                    bind:defaultValue={reservation.nationality}
                    on:setValue={(event) => {
                      if (event.detail?.id != propertyInformation.country.id) {
                        documentDefaultValue = listDocumentsTypes.map(
                          (lst) => ({ ...lst, id: lst.codename })
                        )[0];
                        reservation.documentType =
                          listDocumentsTypes[0].codename;
                        showDocumentType = false;
                        setTimeout(() => (showDocumentType = true), 5);
                      }
                      reservation.nationality = event.detail;
                    }}
                  />
                </div>
              </div>
              <div class="row">
                {#if propertyInformation.id !== "155"}
                  <div class="mb-4 col-12 col-sm-12 col-lg-6">
                    <label
                      for="nationality"
                      class="form-label label__data {!reservation.documentType &&
                      $dataForm.documentType?.touched
                        ? 'field-invalid'
                        : ''}"
                    >
                      {$_("Document type")}
                    </label>
                    {#if showDocumentType}
                      <select
                        use:validators={[required]}
                        name="documentType"
                        id="documentType"
                        class="form-select"
                        on:change={(event) => {
                          if (reservation.documentType !== event.target.value) {
                            reservation.documentImagenBack = {};
                            reservation.documentImagenFront = {};
                          }
                          reservation.documentType = event.target.value;
                        }}
                        bind:value={reservation.documentType}
                      >
                        {#if reservation.nationality && reservation.nationality.id != propertyInformation.country.id}
                          <option value={listDocumentsTypes[0].codename}>
                            {$_(listDocumentsTypes[0].name)}
                          </option>
                          {#if reservation.type == "child" || reservation.type == "baby"}
                            <option value="birth-certificate">
                              {$_("Birth Certificate")}
                            </option>
                          {/if}
                        {:else}
                          {#each listDocumentsTypes as docType}
                            <option value={docType.codename}>
                              {$_(docType.name)}
                            </option>
                          {/each}
                        {/if}
                      </select>
                    {/if}
                    <Hint
                      class="help-block help-block-left field-invalid"
                      for="documentType"
                      on="required"
                    >
                      {$_("This field is required")}
                    </Hint>
                  </div>
                  <div class="mb-4 col-12 col-sm-12 col-lg-6">
                    <label
                      for="nationality"
                      class="form-label label__data {!$dataForm.documentNumber
                        ?.valid && $dataForm.documentNumber?._touched
                        ? 'field-invalid'
                        : ''}"
                    >
                      {$_("Document number")}
                    </label>
                    <input
                      use:validators={[required, maxLength(100)]}
                      name="documentNumber"
                      id="documentNumber"
                      type="text"
                      class="form-control"
                      autocomplete="off"
                      maxlength="40"
                      placeholder={$_("Enter your document number")}
                      on:input={(ev) => limitField(ev, /[^a-z0-9-]/gi)}
                      bind:value={reservation.documentNumber}
                    />
                    <Hint
                      class="help-block help-block-left field-invalid"
                      for="documentNumber"
                      on="required"
                    >
                      {$_("This field is required")}
                    </Hint>
                  </div>
                {/if}
              </div>

              <div class="row">
                {#if reservation.documentType != "birth-certificate" && propertyInformation.id !== "155"}
                  <div class="mb-4 col-12 col-sm-12 col-lg-6">
                    <label
                      for="documentExpirationDate"
                      class="form-label label__data {!$dataForm
                        .documentExpirationDate?.valid &&
                      $dataForm.documentExpirationDate?._touched
                        ? 'field-invalid'
                        : ''}"
                    >
                      {$_("Document expiration date")}
                    </label>
                    <input
                      use:validators={[required, dateIsFuture]}
                      type="date"
                      class="form-control"
                      id="documentExpirationDate"
                      name="documentExpirationDate"
                      autocomplete="off"
                      placeholder={$_("Ex") + ": 12/2030"}
                      min={(() => format(new Date(), "yyyy-MM-dd"))()}
                      bind:value={reservation.documentExpirationDate}
                    />
                    <HintGroup for="documentExpirationDate">
                      <Hint
                        class="help-block help-block-left field-invalid"
                        on="required">{$_("This field is required")}</Hint
                      >

                      <Hint
                        class="help-block help-block-left field-invalid"
                        on="dateIsFuture"
                        hideWhenRequired
                        let:value
                      >
                        {$_("The document has already expired")}
                      </Hint>
                    </HintGroup>
                  </div>
                {/if}

                {#if reservation.type != "child" && reservation.type != "baby" && propertyInformation.id !== "155"}
                  <div class="mb-4 col-12 col-sm-12 col-lg-6">
                    <label for="profession" class="form-label label__data">
                      {$_("Profession")}
                    </label>
                    <input
                      use:validators={[maxLength(40)]}
                      type="text"
                      class="form-control"
                      id="profession"
                      name="profession"
                      on:input={(ev) => limitField(ev, /[^a-zÀ-ÿñ\s]/gi)}
                      autocomplete="honorific-prefix"
                      maxlength="40"
                      placeholder={$_("Enter your profession")}
                      bind:value={reservation.profession}
                    />
                  </div>
                {/if}
              </div>
              {#if propertyInformation.id !== "155"}
                <div class="row">
                  <div class="mb-4 col-12 col-sm-12 col-lg-12">
                    <label
                      for="address"
                      class="form-label label__data {!$dataForm.address
                        ?.valid && $dataForm.address?._touched
                        ? 'field-invalid'
                        : ''}"
                    >
                      {$_("Residence address")}
                    </label>
                    <input
                      use:validators={[required, maxLength(100)]}
                      type="text"
                      class="form-control placehold__color"
                      id="address"
                      name="address"
                      autocomplete="street-address"
                      placeholder={$_("Enter your location of residence")}
                      on:input={(ev) => limitField(ev, /[^a-z0-9À-ÿñ.:,°\s]/gi)}
                      maxlength="100"
                      bind:value={reservation.address}
                    />
                    <div style="display: flex;">
                      <Hint
                        class="help-block help-block-left field-invalid"
                        for="address"
                        on="required">{$_("This field is required")}</Hint
                      >
                      <span class="help-block help-block-right">
                        {reservation.address?.length || 0}/100
                      </span>
                    </div>
                  </div>
                </div>
              {/if}
              <div class="row">
                <div class="mb-4 col-12 col-sm-12 col-lg-6">
                  <label
                    for="phoneNumber"
                    class="form-label label__data {!$dataForm.phoneNumber
                      ?.valid && $dataForm.phoneNumber?._touched
                      ? 'field-invalid'
                      : ''}"
                  >
                    {$_("Phone number")}
                  </label>
                  <input
                    use:validators={[required, maxLength(40)]}
                    type="tel"
                    class="form-control placehold__color"
                    id="phoneNumber"
                    name="phoneNumber"
                    autocomplete="tel"
                    on:input={(ev) => limitField(ev, /[^0-9+]/gi)}
                    placeholder={`${$_("Ex")}: +000000000000`}
                    maxlength="40"
                    bind:value={reservation.phoneNumber}
                  />
                  <Hint
                    class="help-block help-block-left field-invalid"
                    for="phoneNumber"
                    on="required"
                  >
                    {$_("This field is required")}
                  </Hint>
                </div>

                <div class="mb-4 col-12 col-sm-12 col-lg-6">
                  <label
                    for="email"
                    class="form-label label__data {!checkInvalidForm(
                      $dataForm.email
                    )?.valid && $dataForm.email?._touched
                      ? 'field-invalid'
                      : ''}"
                  >
                    {$_("Email")}
                  </label>
                  <input
                    use:validators={[required, email, maxLength(40)]}
                    type="text"
                    class="form-control placehold__color"
                    id="email"
                    name="email"
                    autocomplete="email"
                    pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                    maxlength="40"
                    placeholder={$_("Ex: mail@email.com")}
                    on:input={(ev) => limitField(ev, /[^a-z0-9-_.@]/gi)}
                    bind:value={reservation.email}
                  />
                  <Hint
                    class="help-block help-block-left field-invalid"
                    for="email"
                    on="required"
                  >
                    {$_("This field is required")}
                  </Hint>
                </div>
              </div>

              {#if royaltyProgram.enabled && reservation.type != "child" && reservation.type != "baby"}
                <div class="row">
                  <div class="mb-4 col-12 col-sm-12 col-lg-6">
                    <label for="afiliateNumber" class="form-label label__data">
                      {royaltyProgram.title}
                    </label>
                    <input
                      use:validators={[maxLength(40)]}
                      type="text"
                      class="form-control placehold__color"
                      id="afiliateNumber"
                      name="afiliateNumber"
                      maxlength="40"
                      on:input={(ev) => limitField(ev)}
                      placeholder={$_("Enter your membership")}
                      bind:value={reservation.afiliateNumber}
                    />
                    <span class="help-block help-block-left">
                      {$_("Optional")}
                    </span>
                  </div>
                </div>
              {/if}
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="my-footer-button" style={readOnly ? "pointer-events: none;" : ""}>
    <div class="row">
      <div id="continue" class="col-12 offset-md-9 col-md-3">
        <button
          type="button"
          class="btn btn-primary btn-lg btn-block"
          disabled={!$dataForm.valid || !reservation.nationality}
          on:click={nextStage}
          style="background-color: {guestAppSettings.color};"
        >
          {$_("Continue")}
        </button>
      </div>
    </div>
  </div>
{/if}

<style>
  .my-footer-button {
    z-index: 2;
  }
</style>
